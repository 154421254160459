export const isTouchPointer = () => {
  return matchMedia("(pointer: coarse)").matches;
};

export const matchMedia = () => {
  const sm = !window.matchMedia("(min-width: 550px)").matches;
  const med = window.matchMedia("(min-width: 550px)").matches;
  const lg = window.matchMedia("(min-width: 1100px)").matches;
  const xl = window.matchMedia("(min-width: 1500px)").matches;

  switch (true) {
    case xl:
      return "xl";
    case lg:
      return "lg";
    case med:
      return "med";
    case sm:
      return "sm";
    default:
      return "sm";
  }
};
